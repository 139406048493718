/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearFormErrors(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
    $(form).find('.form-control .is-invalid').hide();
}

export default function (formElement, payload) {
    // clear form validation first
    clearFormErrors(formElement);
    $('.alert', formElement).remove();

    if (typeof payload === 'object' && payload.fields) {
        Object.keys(payload.fields).forEach((key) => {
            if (payload.fields[key]) {
                let feedbackElement = $(formElement)
                    .find(`[name="${key}"]`)
                    .parent()
                    .children('.invalid-feedback');

                // Above selector doesn't work on updated account page, try again
                feedbackElement =
                    feedbackElement.length > 0
                        ? feedbackElement
                        : $(formElement)
                              .find(`[name="${key}"]`)
                              .closest('.custom-input')
                              .children('.invalid-feedback');

                if (feedbackElement.length > 0) {
                    if (Array.isArray(payload[key])) {
                        feedbackElement.html(payload.fields[key].join('<br/>'));
                    } else {
                        feedbackElement.html(payload.fields[key]).show();
                    }
                    feedbackElement.siblings('.form-control').addClass('is-invalid');
                }
            }
        });
    }
    if (payload && payload.error) {
        const form =
            $(formElement).prop('tagName') === 'FORM'
                ? $(formElement)
                : $(formElement).parents('form');

        form.prepend(`<div class="alert alert-danger">${payload.error.join('<br/>')}</div>`);
    }
}

/* globals grecaptcha */

if (typeof window.recaptchaInstances === 'undefined') {
    window.recaptchaInstances = {};
}

const captcha = {
    /**
     * @description adds a unique ID for captcha id data attribute
     */
    addCaptchaId(element) {
        if (!element.dataset.captchaId) {
            // eslint-disable-next-line no-param-reassign
            element.dataset.captchaId =
                Date.now().toString(36) +
                Math.random().toString(36).substring(2, 12).padStart(12, 0);
        }
    },

    /**
     * @description adds an input to a form denoting it has fallen back to recaptcha v2
     */
    addV2Input(form) {
        if (form && !form.querySelector('[name="recaptchaV2"]')) {
            const v2Input = document.createElement('input');
            v2Input.type = 'hidden';
            v2Input.name = 'recaptchaV2';
            v2Input.value = 'true';
            form.appendChild(v2Input);
        }
    },

    /**
     * @description removes v2 input from form
     */
    removeV2Input(form) {
        const recaptchaV2Input = form?.querySelector('[name="recaptchaV2"]');

        if (recaptchaV2Input) {
            recaptchaV2Input.remove();
        }
    },

    /**
     * @description Shows recaptcha error messaging in form
     */
    showRecaptchaError(form, message, container) {
        const formInputs = form.querySelectorAll('.custom-input');
        const lastInput = formInputs.length > 0 ? formInputs[formInputs.length - 1] : null;
        const errorElement = document.createElement('div');
        errorElement.classList.add('alert', 'alert-danger', 'js-recaptcha-error');
        errorElement.innerText = message;

        if (container) {
            container.appendChild(errorElement);
        } else if (lastInput) {
            lastInput.after(errorElement);
        } else {
            form.appendChild(errorElement);
        }
    },

    /**
     * @description Removes any recaptcha errors in form
     */
    clearErrors(form) {
        form.querySelectorAll('.js-recaptcha-error').forEach((error) => {
            error.remove();
        });
    },

    /**
     * @description execute reCAPTCHA
     */
    execute(captchaIndex) {
        if (typeof grecaptcha !== 'undefined') {
            if (captchaIndex) {
                grecaptcha.execute(captchaIndex);
            } else {
                grecaptcha.execute();
            }
        }
    },

    /**
     * @function
     * @description reset reCAPTCHA
     * assumes single reCAPTCHA instance per page
     */
    reset(captchaIndex) {
        if (typeof grecaptcha !== 'undefined') {
            if (captchaIndex) {
                grecaptcha.reset(window.recaptchaInstances[captchaIndex]);
            } else {
                grecaptcha.reset();
            }
        }
    },

    handleV3Submit(form, action, v3SiteKey) {
        const v3Promise = new Promise((resolve) => {
            grecaptcha.ready(() => {
                grecaptcha
                    .execute(v3SiteKey, {
                        action,
                    })
                    .then((token) => {
                        const responseInput = form.querySelector('[name="g-recaptcha-response"]');
                        const actionInput = form.querySelector('[name="g-recaptcha-action"]');

                        if (!responseInput) {
                            const newResponseInput = document.createElement('input');
                            newResponseInput.type = 'hidden';
                            newResponseInput.value = token;
                            newResponseInput.name = 'g-recaptcha-response';
                            form.appendChild(newResponseInput);
                        } else {
                            responseInput.value = token;
                        }

                        if (!actionInput) {
                            const newActionInput = document.createElement('input');
                            newActionInput.type = 'hidden';
                            newActionInput.value = action;
                            newActionInput.name = 'g-recaptcha-action';
                            form.appendChild(newActionInput);
                        } else {
                            actionInput.value = action;
                        }

                        resolve();
                    });
            });
        });

        return v3Promise;
    },

    v2Execute(form) {
        const recaptchaContainer = form.querySelector('.recaptcha-container');

        if (!recaptchaContainer) {
            return;
        }

        captcha.addV2Input(form);

        if (!recaptchaContainer.dataset.captchaId) {
            const { v2SiteKey } = window.patagonia.clientDataModel.recaptcha;

            captcha.addCaptchaId(recaptchaContainer);
            captcha.reset(recaptchaContainer.dataset.captchaId);

            grecaptcha.ready(() => {
                window.recaptchaInstances[recaptchaContainer.dataset.captchaId] = grecaptcha.render(
                    recaptchaContainer,
                    {
                        sitekey: v2SiteKey,
                    }
                );

                captcha.execute(window.recaptchaInstances[recaptchaContainer.dataset.captchaId]);
            });
        } else {
            captcha.reset(recaptchaContainer.dataset.captchaId);
            captcha.execute(window.recaptchaInstances[recaptchaContainer.dataset.captchaId]);
        }
    },

    // Only load recaptcha if it is enabled, has a protected form on the page, and has not already been initialized
    // Force load can be used if an element with class `g-recaptcha` is not in the DOM at the time this is called, but it is still desired for recaptcha to load, i.e. with a lit element
    init(forceLoad) {
        if (
            window.patagonia.clientDataModel.recaptcha.enabled &&
            !window.captchaInitialized &&
            (document.querySelector('.g-recaptcha') || forceLoad)
        ) {
            const { v2SiteKey, v3SiteKey } = window.patagonia.clientDataModel.recaptcha;
            window.captchaInitialized = true;

            // Once v3 script loads and this callback is called, initialize v2
            window.recaptchaV3Loaded = function () {
                const recaptchaContainers = document.querySelectorAll('.recaptcha-container');

                grecaptcha.ready(() => {
                    recaptchaContainers.forEach((recaptchaContainer) => {
                        captcha.addCaptchaId(recaptchaContainer);

                        window.recaptchaInstances[recaptchaContainer.dataset.captchaId] =
                            grecaptcha.render(recaptchaContainer, {
                                sitekey: v2SiteKey,
                            });
                    });

                    // If v2 input already exists on page load, trigger challenge to display
                    const recaptchaV2Input = document.querySelector(
                        'form input[name="recaptchaV2"]'
                    );
                    if (recaptchaV2Input) {
                        captcha.v2Execute(recaptchaV2Input.closest('form'));
                    }
                });
            };

            // Load v3 Script
            $.getScript(
                `https://www.google.com/recaptcha/api.js?render=${v3SiteKey}&onload=recaptchaV3Loaded`,
                () => {}
            );

            document.addEventListener('click', (e) => {
                const submitButton = e.target.closest(
                    'button.g-recaptcha:not(.js-alt-recaptcha-handling)'
                );
                if (!submitButton) return;

                e.preventDefault();

                const form = submitButton.closest('form');
                const action = submitButton.dataset.recaptchaAction;

                captcha.handleV3Submit(form, action, v3SiteKey).then(() => {
                    form.requestSubmit();
                });
            });
        }
    },
};

export default captcha;
export const { init } = captcha;
export const { clearErrors } = captcha;
export const { v2Execute } = captcha;
export const { showRecaptchaError } = captcha;
export const { removeV2Input } = captcha;
export const { handleV3Submit } = captcha;

import formValidation from '../components/formValidation';
import * as recaptcha from '../thirdParty/recaptcha';

export default {
    resetPasswordRequest() {
        document.addEventListener('submit', (e) => {
            const resetPasswordForm = e.target.closest('form.reset-password-form');
            if (!resetPasswordForm) return;

            e.preventDefault();

            // Remove any existing alert errors
            recaptcha.clearErrors(resetPasswordForm);

            // create the pristine instance
            const pristine = new Pristine(resetPasswordForm[0]);
            const valid = pristine.validate();

            if (!valid) {
                pristine.showFormErrors();
                return;
            }

            $(resetPasswordForm).spinner().start();

            const formData = new FormData(resetPasswordForm);

            fetch(resetPasswordForm.action, {
                method: 'POST',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    $(resetPasswordForm).spinner().stop();

                    if (data.success) {
                        const body = resetPasswordForm.querySelector('.request-password-body');
                        const jpPrivacyAndTerms = resetPasswordForm.querySelector(
                            '.js-jp-reset-password-privacy-terms-container'
                        );
                        const submitButtonContainer =
                            resetPasswordForm.querySelector('.send-email-btn');
                        const title = resetPasswordForm.querySelector('.request-password-title');
                        const recaptchaMessaging =
                            resetPasswordForm.querySelector('.recaptcha-messaging');

                        if (title) {
                            title.innerHTML = data.receivedMsgHeading;
                        }

                        if (body) {
                            body.innerHTML = `<p>${data.receivedMsgBody}</p>`;
                        }

                        if (jpPrivacyAndTerms) {
                            jpPrivacyAndTerms.classList.add('d-none');
                        }

                        if (recaptchaMessaging) {
                            recaptchaMessaging.classList.add('d-none');
                        }

                        if (submitButtonContainer) {
                            if (!data.mobile) {
                                submitButtonContainer.innerHTML = '';
                            } else {
                                submitButtonContainer.innerHTML = `<a href="${data.returnUrl}" class="btn btn-primary d-block">${data.buttonText}</a>`;
                            }
                        }
                    } else if (data.recaptchaV3Error) {
                        if (data.errorMessage) {
                            recaptcha.showRecaptchaError(resetPasswordForm, data.errorMessage);
                        }

                        recaptcha.v2Execute(resetPasswordForm);
                    } else if (data.recaptchaV2Error) {
                        if (data.errorMessage) {
                            recaptcha.showRecaptchaError(resetPasswordForm, data.errorMessage);
                        }
                    } else {
                        recaptcha.removeV2Input(resetPasswordForm);
                        formValidation(resetPasswordForm, data);
                    }
                })
                .catch((error) => {
                    $(resetPasswordForm).spinner().stop();
                    console.error('Error requesting password reset: ', error);
                });
        });

        // Resubmit the form on recaptcha v2 callback
        window.v2PasswordResetRequestCallback = function () {
            const resetPasswordForm = document.querySelector('form.reset-password-form');
            resetPasswordForm.requestSubmit();
        };

        // Modal shown success event
        document.addEventListener('passwordResetModalShown', () => {
            recaptcha.init(); // Reinitialize recaptcha in case it hasn't already been initialized

            // If password reset modal is clicked from pro login or session timeout login modals, hide the login modal
            const logInModal = document.querySelector('.js-login-modal');
            let loginModalActive = false;
            if (logInModal) {
                const modal = window.bootstrap.Modal.getInstance(logInModal);
                if (modal) {
                    loginModalActive = true;
                    modal.hide();
                }
            }

            // Populate existing email address into form
            const loginFormEmail = loginModalActive
                ? logInModal.querySelector('.js-login-form-email')
                : document.querySelector(':not(.js-login-modal) > .js-login-form-email');
            const resetPasswordEmail = document.querySelector('.js-reset-password-email');

            if (loginFormEmail && resetPasswordEmail && loginFormEmail.value) {
                resetPasswordEmail.value = loginFormEmail.value;
                resetPasswordEmail.dispatchEvent(new Event('blur'));
            }
        });
    },
};

const tenDays = 10 * 24 * 60 * 60;
const defaultExpSecs = tenDays;

const getExpiryTimestamp = (seconds = defaultExpSecs) => {
    const today = Date.now();
    // convert seconds to milliseconds
    return today + Number(seconds * 1000);
};

const getDataFromExpiryCombinedObj = (key = null, rawData = '') => {
    try {
        const data = JSON.parse(rawData);
        if ('expiry' in data && data.expiry) {
            try {
                const expiryDate = new Date(Number(data.expiry)).valueOf();
                if (expiryDate < Date.now()) {
                    console.info(`${key} expired from storage`);
                    return null;
                }
                console.info(`${key} valid from storage`);
                return data.value;
            } catch (e) {
                console.warn('storage Date error', e);
                return null;
            }
        }
    } catch (e) {
        // console.warn('storage JSON.parse error', e);
        return null;
    }
    return null;
};

const getDataFromStorage = (key = null) => {
    if (!key) {
        return null;
    }

    const rawData = window.localStorage.getItem(key);

    if (rawData === null || rawData === 'undefined') {
        // console.info(`${key} not found from storage`);
        return null;
    }

    return getDataFromExpiryCombinedObj(key, rawData);
};

const buildExpiryCombinedObj = (value, expirySeconds = defaultExpSecs) => ({
    value,
    expiry: getExpiryTimestamp(expirySeconds),
});

// @todo add set/get with TTL handling

export const setItem = (key, str, expirySeconds = defaultExpSecs) => {
    const data = buildExpiryCombinedObj(str, expirySeconds);
    window.localStorage.setItem(key, JSON.stringify(data));
};

export const getItem = (key) => {
    const data = getDataFromStorage(key);
    if (typeof data !== 'string') {
        // console.info('storage.getItem error', key);
        return null;
    }
    return data;
};

export const setJSON = (key, obj, expirySeconds = defaultExpSecs) => {
    if (typeof obj === 'object') {
        const data = buildExpiryCombinedObj(obj, expirySeconds);
        window.localStorage.setItem(key, JSON.stringify(data));
    } else {
        // console.info('storage.setJSON error', key, obj);
    }
};

export const getJSON = (key) => {
    const data = getDataFromStorage(key);
    if (typeof data !== 'object') {
        // console.info('storage.getJSON error', key, data);
        return null;
    }
    return data;
};

import ajaxZip3 from './ajaxzip3';

// Regex taken from byte-counter.js double byte checker
const regexFullWidthCharacters =
    /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/;

/**
 * @function
 * @description Convert double byte characters in a field to single byte
 * @param {Object} field jquery element
 * @param {Function} callback callback function for post-conversion handling
 */
export const doubleToSingle = (field, callback) => {
    if (regexFullWidthCharacters.test(field.val())) {
        ajaxZip3.zenToHan(field.val(), (fieldValue) => {
            field.val(fieldValue.trim());
            // revalidate the field to remove special character error messaging
            field.trigger('input');
        });
    }
    if (callback) callback(field);
};

/**
 * @function
 * @description Convert double byte characters in a string to single byte
 * @param {String} val string to convert
 * @returns {String} converted string
 */
export const doubleToSingleString = (val) => {
    if (regexFullWidthCharacters.test(val)) {
        return ajaxZip3.zenToHan(val, (updatedValue) => updatedValue.trim());
    }

    return val;
};

/**
 * Bloomreach Page View Event
 * @param {String} referrerURL - Original referrer URL
 */
function blrPageViewEvent(referrerURL) {
    const blrTrk = window.BrTrk;

    if (typeof blrTrk !== 'undefined') {
        const brData = window?.br_data || {};

        // this parameter is required on all Virtual Page Loads
        brData.orig_ref_url = referrerURL;

        blrTrk.getTracker().updateBrData(brData);
        blrTrk.getTracker().logPageView();
    }
}

/**
 * Bloomreach Track Event
 * @param {String} eventGroup - Pixel tracking event group
 * @param {String} eventType - Pixel tracking event type
 * @param {Object} eventData - Pixel tracking event data
 */
function blrTrackEvent(eventGroup, eventType, eventData) {
    const blrTrk = window.BrTrk;

    if (typeof blrTrk !== 'undefined') {
        if (eventGroup === 'suggest') {
            blrTrk.getTracker().logEvent(eventGroup, eventType, eventData, {}, true);
        } else {
            blrTrk.getTracker().logEvent(eventGroup, eventType, eventData);
        }
    }
}

export { blrPageViewEvent, blrTrackEvent };

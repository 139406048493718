/**
 * Calls a function after DOM content has loaded, or immediately if it has already loaded
 * @param {Function} func - function to call after DOM is ready
 * @param {*} arg1 - function argument
 */
function loadAfterDOMContentLoaded(func, arg1) {
    if (/complete|interactive|loaded/.test(document.readyState)) {
        func(arg1);
    } else {
        document.addEventListener('DOMContentLoaded', () => {
            func(arg1);
        });
    }
}

/**
 * Calls a function after document and all sub-resources have loaded
 * @param {Function} func - function to call after DOM is ready
 * @param {*} arg1 - function argument
 */
function loadAfterComplete(func, arg1) {
    if (document.readyState === 'complete') {
        func(arg1);
    } else {
        window.onload = () => {
            func(arg1);
        };
    }
}

export { loadAfterDOMContentLoaded, loadAfterComplete };

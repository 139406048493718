/* eslint-disable
    no-cond-assign,
    no-func-assign,
    no-loop-func,
    no-nested-ternary,
    no-param-reassign,
    no-return-assign,
    no-sequences,
    no-shadow,
    no-unused-expressions,
    no-unused-vars,
    no-use-before-define
*/

function _typeof(obj) {
    '@babel/helpers - typeof';

    return (
        (_typeof =
            typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
                ? function (obj) {
                      return typeof obj;
                  }
                : function (obj) {
                      return obj &&
                          typeof Symbol === 'function' &&
                          obj.constructor === Symbol &&
                          obj !== Symbol.prototype
                          ? 'symbol'
                          : typeof obj;
                  }),
        _typeof(obj)
    );
}
var _class;
var _descriptor;
var _descriptor2;
var _descriptor3;
var _descriptor4;
var _descriptor5;
var _descriptor6;
var _descriptor7;
var _descriptor8;
var _descriptor9;
var _descriptor10;
var _descriptor11;
var _descriptor12;
var _descriptor13;

function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        enumerableOnly &&
            (symbols = symbols.filter(
                (sym) => Object.getOwnPropertyDescriptor(object, sym).enumerable
            )),
            keys.push.apply(keys, symbols);
    }
    return keys;
}

function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i += 1) {
        var source = arguments[i] != null ? arguments[i] : {};
        i % 2
            ? ownKeys(Object(source), !0).forEach((key) => {
                  _defineProperty(target, key, source[key]);
              })
            : Object.getOwnPropertyDescriptors
              ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source))
              : ownKeys(Object(source)).forEach((key) => {
                    Object.defineProperty(
                        target,
                        key,
                        Object.getOwnPropertyDescriptor(source, key)
                    );
                });
    }
    return target;
}

function _initializerDefineProperty(target, property, descriptor, context) {
    if (!descriptor) return;
    Object.defineProperty(target, property, {
        enumerable: descriptor.enumerable,
        configurable: descriptor.configurable,
        writable: descriptor.writable,
        value: descriptor.initializer ? descriptor.initializer.call(context) : undefined,
    });
}

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}

function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i += 1) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor) descriptor.writable = true;
        Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor);
    }
}

function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps) _defineProperties(Constructor.prototype, protoProps);
    if (staticProps) _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}

function _defineProperty(obj, key, value) {
    key = _toPropertyKey(key);
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true,
        });
    } else {
        obj[key] = value;
    }
    return obj;
}

function _toPropertyKey(arg) {
    var key = _toPrimitive(arg, 'string');
    return _typeof(key) === 'symbol' ? key : String(key);
}

function _toPrimitive(input, hint) {
    if (_typeof(input) !== 'object' || input === null) return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || 'default');
        if (_typeof(res) !== 'object') return res;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return (hint === 'string' ? String : Number)(input);
}

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object.keys(descriptor).forEach((key) => {
        desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;
    if ('value' in desc || desc.initializer) {
        desc.writable = true;
    }
    desc = decorators
        .slice()
        .reverse()
        .reduce((desc, decorator) => decorator(target, property, desc) || desc, desc);
    if (context && desc.initializer !== undefined) {
        desc.value = desc.initializer ? desc.initializer.call(context) : undefined;
        desc.initializer = undefined;
    }
    if (desc.initializer === undefined) {
        Object.defineProperty(target, property, desc);
        desc = null;
    }
    return desc;
}

function _initializerWarningHelper(descriptor, context) {
    throw new Error(
        'Decorating class property failed. Please ensure that ' +
            'proposal-class-properties is enabled and runs after the decorators transform.'
    );
}

var _require = require('mobx'); // eslint-disable-line import/no-extraneous-dependencies
var { computed, observable } = _require;

var Store =
    ((_class = /* #__PURE__ */ (function () {
        function Store() {
            _classCallCheck(this, Store);
            _defineProperty(this, 'MASKED_CC_PREFIX', '•••');
            _initializerDefineProperty(this, 'checkout', _descriptor, this);
            _initializerDefineProperty(this, 'endDigits', _descriptor2, this);
            _initializerDefineProperty(this, 'selectedMethod', _descriptor3, this);
            _initializerDefineProperty(this, 'componentsObj', _descriptor4, this);
            _initializerDefineProperty(this, 'checkoutConfiguration', _descriptor5, this);
            _initializerDefineProperty(this, 'formErrorsExist', _descriptor6, this);
            _initializerDefineProperty(this, 'isValid', _descriptor7, this);
            _initializerDefineProperty(this, 'paypalTerminatedEarly', _descriptor8, this);
            _initializerDefineProperty(this, 'componentState', _descriptor9, this);
            _initializerDefineProperty(this, 'brand', _descriptor10, this);
            _initializerDefineProperty(this, 'partialPaymentsOrderObj', _descriptor11, this);
            _initializerDefineProperty(
                this,
                'giftCardComponentListenersAdded',
                _descriptor12,
                this
            );
            _initializerDefineProperty(this, 'addedGiftCards', _descriptor13, this);
        }
        _createClass(Store, [
            {
                key: 'maskedCardNumber',
                get: function get() {
                    return ''.concat(this.MASKED_CC_PREFIX).concat(this.endDigits);
                },
            },
            {
                key: 'selectedPayment',
                get: function get() {
                    return this.componentsObj[this.selectedMethod];
                },
            },
            {
                key: 'selectedPaymentIsValid',
                get: function get() {
                    // Previously checked this.selectedPayment.isValid, which was sometimes incorrect when the first payment method was not a credit card (it would validate the first payment method as if it were the "scheme" method). The node seems to always be correct for the selectedPayment, so using that instead.
                    return !!(
                        this.selectedPayment &&
                        this.selectedPayment.node &&
                        this.selectedPayment.node.isValid
                    );
                },
            },
            {
                key: 'stateData',
                get: function get() {
                    // Previously referenced this.selectedPayment.stateData, which was sometimes incorrect when the first payment method was not a credit card. The node seems to always be correct for the selectedPayment, so using that instead via this.selectedPayment.node.data.
                    return (
                        (this.selectedPayment === null || this.selectedPayment === undefined
                            ? undefined
                            : this.selectedPayment.node.data) || {
                            paymentMethod: _objectSpread(
                                {
                                    type: this.selectedMethod,
                                },
                                this.brand
                                    ? {
                                          brand: this.brand,
                                      }
                                    : undefined
                            ),
                        }
                    );
                },
            },
            {
                key: 'updateSelectedPayment',
                value: function updateSelectedPayment(method, key, val) {
                    this.componentsObj[method][key] = val;
                },
            },
        ]);
        return Store;
    })()),
    ((_descriptor = _applyDecoratedDescriptor(_class.prototype, 'checkout', [observable], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null,
    })),
    (_descriptor2 = _applyDecoratedDescriptor(_class.prototype, 'endDigits', [observable], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null,
    })),
    (_descriptor3 = _applyDecoratedDescriptor(_class.prototype, 'selectedMethod', [observable], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null,
    })),
    (_descriptor4 = _applyDecoratedDescriptor(_class.prototype, 'componentsObj', [observable], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: function initializer() {
            return {};
        },
    })),
    (_descriptor5 = _applyDecoratedDescriptor(
        _class.prototype,
        'checkoutConfiguration',
        [observable],
        {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: function initializer() {
                const adyenConfiguration = window.patagonia.clientDataModel.adyen.configuration;
                return adyenConfiguration || {};
            },
        }
    )),
    (_descriptor6 = _applyDecoratedDescriptor(_class.prototype, 'formErrorsExist', [observable], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null,
    })),
    (_descriptor7 = _applyDecoratedDescriptor(_class.prototype, 'isValid', [observable], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: function initializer() {
            return false;
        },
    })),
    (_descriptor8 = _applyDecoratedDescriptor(
        _class.prototype,
        'paypalTerminatedEarly',
        [observable],
        {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: function initializer() {
                return false;
            },
        }
    )),
    (_descriptor9 = _applyDecoratedDescriptor(_class.prototype, 'componentState', [observable], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: function initializer() {
            return {};
        },
    })),
    (_descriptor10 = _applyDecoratedDescriptor(_class.prototype, 'brand', [observable], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null,
    })),
    (_descriptor11 = _applyDecoratedDescriptor(
        _class.prototype,
        'partialPaymentsOrderObj',
        [observable],
        {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null,
        }
    )),
    (_descriptor12 = _applyDecoratedDescriptor(
        _class.prototype,
        'giftCardComponentListenersAdded',
        [observable],
        {
            configurable: true,
            enumerable: true,
            writable: true,
            initializer: null,
        }
    )),
    (_descriptor13 = _applyDecoratedDescriptor(_class.prototype, 'addedGiftCards', [observable], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null,
    })),
    _applyDecoratedDescriptor(
        _class.prototype,
        'maskedCardNumber',
        [computed],
        Object.getOwnPropertyDescriptor(_class.prototype, 'maskedCardNumber'),
        _class.prototype
    ),
    _applyDecoratedDescriptor(
        _class.prototype,
        'selectedPayment',
        [computed],
        Object.getOwnPropertyDescriptor(_class.prototype, 'selectedPayment'),
        _class.prototype
    ),
    _applyDecoratedDescriptor(
        _class.prototype,
        'selectedPaymentIsValid',
        [computed],
        Object.getOwnPropertyDescriptor(_class.prototype, 'selectedPaymentIsValid'),
        _class.prototype
    ),
    _applyDecoratedDescriptor(
        _class.prototype,
        'stateData',
        [computed],
        Object.getOwnPropertyDescriptor(_class.prototype, 'stateData'),
        _class.prototype
    )),
    _class);

module.exports = new Store();

import debounce from 'lodash/debounce';
import { init as quickAddInit, methods as quickAddMethods } from '../product/quickAdd';
import { initHandling as wishlistTiles } from '../wishlist/wishlistTiles';
import { getBreakPoint } from '../util';

const maxTealiumSends = 15;
let sentTileImpressions = 0;
let productsWaitingToGoToTealium = 0;
let sendTileImpressionsInterval = null;
let productTileObserver = null;
let productTileTrackingActive = true;
const wishlistsEnabled = !!window.patagonia?.clientDataModel?.wishlist;

function deactivateProductImpressions() {
    if (sendTileImpressionsInterval) {
        clearInterval(sendTileImpressionsInterval);
    }
    productTileTrackingActive = false;
}

/**
 * Add Viewed Badge Status Indicator
 */
function addViewedBadge(target = document) {
    // Make sure Session Storage is Supported
    if (typeof sessionStorage !== 'undefined') {
        // Get Viewed Items from Session Storage
        const sessionData = sessionStorage.getItem('viewed_products');

        // Create placeholder for Viewed Products
        let viewedProducts = [];

        // If we had existing session data, try to parse it
        if (sessionData) {
            try {
                viewedProducts = JSON.parse(sessionData).map((int) => int.toString());
            } catch (err) {
                console.error(err);
            }
        }

        // Initialize View Status Indicator
        if (viewedProducts && viewedProducts.length > 0) {
            // Only check products that have not already checked for a viewed badge
            target.querySelectorAll('.product:not(.badge-loaded)').forEach((product) => {
                const { pid, onesize } = product.dataset;

                if (onesize || pid) {
                    product.classList.add('badge-loaded');
                    const badge = product.querySelector('.viewed-badge');
                    if (badge && onesize) {
                        badge.classList.toggle('d-none', !viewedProducts.includes(onesize));
                    } else if (badge && pid) {
                        badge.classList.toggle('d-none', !viewedProducts.includes(pid));
                    }
                }
            });
        }
    }
}

function calculateSwatchesNotShown() {
    const breakpoints = getBreakPoint();
    const largeBreakpoints = ['xlarge', 'xxlarge', 'xxxlarge', 'xxxxlarge'];
    const smallBreakpoints = ['tiny', 'small', 'medium'];

    const swatchesSpan = document.querySelectorAll('.swatch-next-link__subtr');
    const maxDesktop = 5;
    const maxTablet = 9;
    const maxMobile = 4;

    swatchesSpan.forEach((el) => {
        const swatchLength = el.getAttribute('data-swatch-length');
        const span = el.querySelector('.sbtr-num');

        // for desktop: shows max 5
        if (largeBreakpoints.includes(breakpoints)) {
            if (swatchLength > maxDesktop) {
                span.innerHTML = `+${swatchLength - maxDesktop}`;
            } else {
                span.innerHTML = '';
            }
        }

        // for tablet: shows max 9
        if (breakpoints === 'large') {
            if (swatchLength > maxTablet) {
                span.innerHTML = `+${swatchLength - maxTablet}`;
            } else {
                span.innerHTML = '';
            }
        }

        // for mobile: shows max 4
        if (smallBreakpoints.includes(breakpoints)) {
            if (swatchLength > maxMobile) {
                span.innerHTML = `+${swatchLength - maxMobile}`;
            } else {
                span.innerHTML = '';
            }
        }
    });
}

function handlePercentOffBadge(tile) {
    const currentTile = $(tile);
    const badge = currentTile.find('.badge--percent-off');

    if (badge) {
        badge.addClass('d-none');
        const discountPercent = currentTile
            .find('.color-price.active')
            .attr('data-discount-percent');

        if (discountPercent) {
            const numberPlace = badge.find('span');
            try {
                if (discountPercent < 10) {
                    return;
                }

                numberPlace.html(Math.floor(discountPercent));
                badge.removeClass('d-none');
            } catch (err) {
                console.warn(err);
            }
        }
    }
}

function detectedFirstInteractions(evt) {
    if (evt.detail.uid && evt.detail.uid && evt.detail.uid.startsWith('js-product-tile')) {
        const elm = document.querySelector(evt.detail.selector);
        const currentSlide = elm.dataset?.slide;

        if (currentSlide) {
            const $bgImageSource = document.querySelectorAll(`.${evt.detail.uid}-bg source`);
            const $coverImageSource = document.querySelectorAll(`.${evt.detail.uid}-cover source`);

            if ($bgImageSource) {
                $bgImageSource.forEach((source) => {
                    if (source.srcset === '') {
                        source.setAttribute('srcset', source.dataset.srcset);
                        source.removeAttribute('data-srcset');
                    }
                });
            }
            if ($coverImageSource) {
                $coverImageSource.forEach((source) => {
                    if (source.srcset === '') {
                        source.setAttribute('srcset', source.dataset.srcset);
                        source.removeAttribute('data-srcset');
                    }
                });
            }
        }
    }
}

/**
 * Initializes Product Tile swatch and image functionality
 *
 * @return {undefined}
 */
function addGlobalProductTileEvents() {
    quickAddInit();

    // Add percent off badge to product tiles on initial page load
    document
        .querySelectorAll('.product-tile:not(.skeleton) .badge--percent-off:not(.badge--init)')
        .forEach((badge) => {
            badge.classList.add('badge--init');
            handlePercentOffBadge(badge.closest('.product-tile'));
        });

    if (wishlistsEnabled) {
        wishlistTiles();
    }

    // Preload swatches
    $(document).on('mouseenter', '.product-tile', function () {
        $(this)
            .find('img.lazyload, img.lazyload-manual')
            .not('.lazyloading, .lazyloaded')
            .each(function () {
                if (window.lazySizes) {
                    window.lazySizes.loader.unveil(this);
                }
            });
    });

    // handles 'view more' link in swatch list
    calculateSwatchesNotShown();

    const debouncedSwatchCalculation = debounce(calculateSwatchesNotShown, 150);
    window.addEventListener('resize', debouncedSwatchCalculation);
    document.addEventListener('ReloadModules', debounce(calculateSwatchesNotShown, 100));

    $(document).on(
        'click',
        '.product-tile:not(.product-tile--marketing):not(.marketing-tile) a',
        function () {
            const tileLink = this;
            import(/* webpackPreload: true */ '../app/tealium/tealium.js').then(
                ({ default: tealium }) => {
                    try {
                        tealium.collect(
                            'product_click',
                            JSON.parse(
                                tileLink.closest('.product-tile').getAttribute('data-tealium')
                            )
                        );
                    } catch (e) {
                        console.warn(e);
                    }
                }
            );
        }
    );

    $(document).on('click', '.product-tile .color-swatches', (e) => {
        $(e.target)
            .closest('.product-tile')
            .find('img.lazyload, img.lazyload-manual')
            .not('.lazyloading, .lazyloaded')
            .each(function () {
                if (window.lazySizes) {
                    window.lazySizes.loader.unveil(this);
                }
            });
    });

    $(document).on('click', '.product-tile__colors', function (e) {
        e.stopPropagation();
        e.preventDefault();

        const currentTile = this.closest('.product-tile');
        const currentColor = this.getAttribute('data-color');
        const currentCover =
            currentTile.querySelector(`.product-tile__cover[data-color='${currentColor}']`) ||
            currentTile.querySelector('.product-tile__cover');
        const currentVariationGroup = this.getAttribute('data-variation-group-id');
        const currentCoverLitComponent = currentTile.querySelector('product-tile-image');
        const currentCoverImageComponent =
            currentCoverLitComponent || currentCover.querySelector('picture');
        const currentTileImage = currentTile.querySelector(
            `.product-tile__image[data-color='${currentColor}']`
        );
        let wishlistTealiumData = null;

        try {
            wishlistTealiumData = JSON.parse(this.getAttribute('data-wishlist-tealium-data'));
        } catch (err) {
            console.error('Unable to parse wishlist tealium data');
        }

        const currentImage = currentTileImage.querySelector('img');
        const prevColor = currentTile
            .querySelector('.product-tile__colors.active')
            .getAttribute('data-color');
        const tilePid = currentTile.closest('.product').getAttribute('data-pid');

        // Preload swatches
        $(currentTile)
            .find('img.lazyload, img.lazyload-manual')
            .not('.lazyloading, .lazyloaded')
            .each(function () {
                if (window.lazySizes) {
                    window.lazySizes.loader.unveil(this);
                }
            });

        // handle swatch flags
        currentTile.querySelector('.product-tile__colors.active').classList.remove('active');
        this.classList.add('active');

        // handle image flags
        currentTile.querySelector('.product-tile__image.active').classList.remove('active');
        currentTileImage.classList.add('active');

        // Handle price switch
        const newActive = currentTile.querySelector(`.color-price[data-color='${currentColor}']`);
        currentTile.querySelector('.color-price.active').classList.remove('active');
        newActive.classList.add('active');

        // Handle %Off badge
        handlePercentOffBadge(currentTile);

        // handle quick add button url update
        const quickAddContainer = currentTile.querySelector('.product-tile__quickadd-container');
        const quickAddContainerURL =
            quickAddContainer.getAttribute('data-quickadd-url') + currentColor;
        const quickAddBtn = currentTile.querySelector('.tile-quickadd-btn');
        const quickAddBtnMobile = currentTile.querySelector('.mobile-tile-quickadd-btn');
        quickAddBtn?.setAttribute('data-url', quickAddContainerURL);
        quickAddBtnMobile?.setAttribute('data-url', quickAddContainerURL);

        // handle active show size update
        if (currentTile.querySelectorAll('.show-size-active').length > 0) {
            document?.getElementById('modalQuickAddSizes').remove();
            quickAddMethods.showQuickAddSizes(quickAddContainerURL, quickAddBtn);
        }

        // handle single sizes quick add button
        if (currentTile.querySelector('.product-tile__quickadd-one-size')) {
            const oneSizeData = JSON.parse(currentTileImage.getAttribute('data-one-size'));
            const quickAddAtbBtn = currentTile.querySelector('.quickadd-atb-btn');

            currentTile.querySelector('input#pid').value = oneSizeData.pid;
            if (oneSizeData.inStock) {
                quickAddAtbBtn.classList.remove('disabled');
                quickAddAtbBtn.removeAttribute('disabled');

                quickAddBtnMobile.classList.remove('disabled');
                quickAddBtnMobile.removeAttribute('disabled');
            } else {
                quickAddAtbBtn.classList.add('disabled');
                quickAddAtbBtn.setAttribute('disabled', 'true');

                quickAddBtnMobile.classList.add('disabled');
                quickAddBtnMobile.setAttribute('disabled', 'true');
            }
        }

        if (wishlistsEnabled) {
            document.dispatchEvent(
                new CustomEvent('updateWishlistPID', {
                    detail: {
                        tile: currentTile,
                        variationGroupID: currentVariationGroup,
                        tealiumData: wishlistTealiumData,
                    },
                })
            );
        }

        // if regular tile (newer)
        if (currentCoverImageComponent.className.startsWith('js-product-tile')) {
            const currentDisplay = currentTile.querySelector('.product-tile__cover.d-block');
            if (currentDisplay && currentDisplay !== currentCover) {
                currentDisplay.classList.add('d-none');
                currentDisplay.classList.remove('d-block');

                currentCover.classList.remove('d-none');
                currentCover.classList.add('d-block');
            }
        } else {
            // if featured tile (older - maybe obsolete?)
            const coverData = currentImage.getAttribute('data-cover');
            if (coverData) {
                const jsonCoverData = JSON.parse(coverData);
                const currentSrc = currentCoverImageComponent.getAttribute('base');

                if (
                    jsonCoverData.url.search('noimage') === -1 &&
                    jsonCoverData.url.search(`_${currentColor}`) > -1
                ) {
                    currentCoverImageComponent.setAttribute('alt', jsonCoverData.alt);
                    const link = currentTileImage.querySelector('a');
                    currentCover
                        .querySelector('a')
                        .setAttribute('href', link.getAttribute('href'))
                        .setAttribute('title', link.getAttribute('title'));

                    if (currentSrc !== jsonCoverData.url) {
                        currentCoverImageComponent.setAttribute('base', jsonCoverData.url);
                    }
                } else {
                    const backup = currentImage.querySelector('.product-tile__image.active');
                    const backupSrc = backup.querySelector('picture');
                    const link = backup.querySelector('a');

                    currentCoverImageComponent.setAttribute('alt', backupSrc.getAttribute('alt'));
                    currentCover
                        .querySelector('a')
                        .setAttribute('href', link.getAttribute('href'))
                        .setAttribute('title', link.getAttribute('title'));

                    if (currentSrc !== backupSrc.getAttribute('base')) {
                        currentCoverImageComponent.setAttribute(
                            'base',
                            backupSrc.getAttribute('base')
                        );
                    }
                }
                currentCover.style.display = 'block';
                currentTile.classList.remove('is-cover-hidden');
            } else if (this.classList.contains('default')) {
                currentCover.style.display = 'block';
                currentTile.classList.remove('is-cover-hidden');
            } else {
                currentCover.style.display = 'none';
                currentTile.classList.add('is-cover-hidden');
            }
        }

        // Handle Compare Page - View Product Link
        const compareProductBtn = document.querySelector(
            `.product-header[data-compare-product="${tilePid}"] .product-header--buttons a`
        );
        const compareProductLink = compareProductBtn?.getAttribute('href');
        compareProductBtn?.setAttribute(
            'href',
            compareProductLink.replace(prevColor, currentColor)
        );
    });

    // Remove event listener to prevent multiple firings
    document.removeEventListener('DetectedFirstInteraction', detectedFirstInteractions);
    document.addEventListener('DetectedFirstInteraction', detectedFirstInteractions);

    // Listen for new AJAX Content
    document.dispatchEvent(new CustomEvent('DetectNewFirstInteractions'));
}

function sendProductImpressionsToTealium() {
    sentTileImpressions = sentTileImpressions || 0;

    if (sentTileImpressions <= maxTealiumSends) {
        if (productsWaitingToGoToTealium > 0) {
            sentTileImpressions += 1;
            import(/* webpackPreload: true */ '../app/tealium/tealium.js').then(
                ({ default: tealium }) => {
                    tealium.collect('product_tile_impressions', window.utag_data);
                }
            );
        }
    } else {
        deactivateProductImpressions();
    }

    productsWaitingToGoToTealium = 0;
}

function addTileToDataLayer(tile) {
    if (tile.dataset.tealium) {
        try {
            const data = JSON.parse(tile.dataset.tealium);
            Object.keys(data).forEach((key) => {
                if (!window.utag_data) {
                    window.utag_data = {};
                }

                if (!window.utag_data[key]) {
                    window.utag_data[key] = [];
                }
                window.utag_data[key].push(data[key].pop());
            });
            productsWaitingToGoToTealium = productsWaitingToGoToTealium || 0;
            productsWaitingToGoToTealium += 1;
        } catch (e) {
            console.warn(e);
        }
    }
}

function productTileObserverCallback(entries, observer) {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            addTileToDataLayer(entry.target);
            observer.disconnect(entry.target);
        }
    });

    if (productsWaitingToGoToTealium > 3) {
        debounce(sendProductImpressionsToTealium, 250);
    }
}

function addTracking(target) {
    if (!productTileObserver) {
        productTileObserver = new IntersectionObserver(productTileObserverCallback, {
            threshold: [0.5, 1],
        });
    }
    const tracker = productTileObserver;
    target.querySelectorAll('.product-tile:not(.is-tracked):not(.skeleton)').forEach((tile) => {
        tile.classList.add('is-tracked');

        const { top, left, right } = tile.getBoundingClientRect();
        const inView =
            top >= 0 && top < window.innerHeight && left >= 0 && right <= window.innerWidth;
        if (inView) {
            addTileToDataLayer(tile);
        } else {
            tracker.observe(tile);
        }
    });
}

/**
 * Initializes Product Tile viewed badge functionality
 *
 * @return {undefined}
 */
function initProductTiles(target = document) {
    // Immediately fire Viewed Badge for existing product tiles
    addViewedBadge(target);

    target
        .querySelectorAll('.product-tile:not(.skeleton) .badge--percent-off:not(.badge--init)')
        .forEach((badge) => {
            badge.classList.add('badge--init');
            handlePercentOffBadge(badge.closest('.product-tile'));
        });

    productsWaitingToGoToTealium = productsWaitingToGoToTealium || 0;

    setTimeout(() => {
        deactivateProductImpressions();
    }, 10 * 1000);

    if (productTileTrackingActive && sentTileImpressions <= maxTealiumSends) {
        addTracking(target);
        if (!sendTileImpressionsInterval) {
            sendTileImpressionsInterval = setInterval(() => {
                sendProductImpressionsToTealium();
                if (sentTileImpressions > maxTealiumSends) {
                    deactivateProductImpressions();
                }
            }, 4000);
        }
    }

    // Remove event listener to prevent multiple firings
    document.removeEventListener('DetectedFirstInteraction', detectedFirstInteractions);
    document.addEventListener('DetectedFirstInteraction', detectedFirstInteractions);

    // Listen for new AJAX Content
    document.dispatchEvent(new CustomEvent('DetectNewFirstInteractions'));
}

export { addGlobalProductTileEvents, initProductTiles, calculateSwatchesNotShown };

import { dataPrefix } from './utils';

class ControllerBlob {
    constructor() {
        this.scenes = [];
    }

    init(target) {
        if (!this.target) {
            this.target = target;
        }
        this.scan(target);
    }

    scan(target) {
        this.scenes = [];
        const scenes = target.querySelectorAll(`[${dataPrefix}-scene]`);
        this.addScenes(scenes);
    }

    scanAdd(target) {
        const scenes = target.querySelectorAll(`[${dataPrefix}-scene]:not(.controller-blob-init)`);
        this.addScenes(scenes);
    }

    addScenes(scenes) {
        import('./blob-scene').then(({ default: BlobScene }) => {
            scenes.forEach((el) => {
                const scene = new BlobScene(el);
                scene.paused = false;
                this.scenes.push(scene);
                if (el.querySelector('canvas.controller-blob-added')) {
                    el.querySelector('canvas.controller-blob-added').remove();
                }
            });
        });
    }

    getScene(el) {
        return this.scenes.filter((scene) => scene.el.scene === el)[0];
    }
}

export default window?.patagonia.app?.controllerBlob || new ControllerBlob();

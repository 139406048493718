/* eslint-disable
    no-cond-assign,
    no-func-assign,
    no-loop-func,
    no-nested-ternary,
    no-param-reassign,
    no-return-assign,
    no-sequences,
    no-shadow,
    no-unused-expressions,
    no-use-before-define,
    no-var,
    prefer-rest-params,
    prefer-spread
*/

function _typeof(obj) {
    '@babel/helpers - typeof';

    return (
        (_typeof =
            typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
                ? function (obj) {
                      return typeof obj;
                  }
                : function (obj) {
                      return obj &&
                          typeof Symbol === 'function' &&
                          obj.constructor === Symbol &&
                          obj !== Symbol.prototype
                          ? 'symbol'
                          : typeof obj;
                  }),
        _typeof(obj)
    );
}

function ownKeys(object, enumerableOnly) {
    const keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        let symbols = Object.getOwnPropertySymbols(object);
        enumerableOnly &&
            (symbols = symbols.filter(
                (sym) => Object.getOwnPropertyDescriptor(object, sym).enumerable
            )),
            keys.push.apply(keys, symbols);
    }
    return keys;
}

function _objectSpread(target) {
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < arguments.length; i++) {
        var source = arguments[i] != null ? arguments[i] : {};
        i % 2
            ? ownKeys(Object(source), !0).forEach((key) => {
                  _defineProperty(target, key, source[key]);
              })
            : Object.getOwnPropertyDescriptors
              ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source))
              : ownKeys(Object(source)).forEach((key) => {
                    Object.defineProperty(
                        target,
                        key,
                        Object.getOwnPropertyDescriptor(source, key)
                    );
                });
    }
    return target;
}

function _defineProperty(obj, key, value) {
    key = _toPropertyKey(key);
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value,
            enumerable: true,
            configurable: true,
            writable: true,
        });
    } else {
        obj[key] = value;
    }
    return obj;
}

function _toPropertyKey(arg) {
    const key = _toPrimitive(arg, 'string');
    return _typeof(key) === 'symbol' ? key : String(key);
}

function _toPrimitive(input, hint) {
    if (_typeof(input) !== 'object' || input === null) return input;
    const prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        const res = prim.call(input, hint || 'default');
        if (_typeof(res) !== 'object') return res;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return (hint === 'string' ? String : Number)(input);
}

const store = require('../../../../store');

function assignPaymentMethodValue() {
    const adyenPaymentMethod = document.querySelector('#adyenPaymentMethodName');
    // if currently selected paymentMethod contains a brand it will be part of the label ID
    const paymentMethodlabelId = '#lb_'.concat(store.selectedMethod);
    if (adyenPaymentMethod) {
        let _document$querySelect;
        adyenPaymentMethod.value = store.brand
            ? store.brand
            : (_document$querySelect = document.querySelector(paymentMethodlabelId)) === null ||
                _document$querySelect === undefined
              ? undefined
              : _document$querySelect.innerHTML;
    }
}

function setOrderFormData(response) {
    if (response.orderNo) {
        document.querySelector('#merchantReference').value = response.orderNo;
    }
    if (response.orderToken) {
        document.querySelector('#orderToken').value = response.orderToken;
    }
}

/**
 * Makes an ajax call to the controller function PaymentFromComponent.
 * Used by certain payment methods like paypal
 */
function paymentFromComponent(data) {
    const component = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const requestData = store.partialPaymentsOrderObj
        ? _objectSpread(
              _objectSpread({}, data),
              {},
              {
                  partialPaymentsOrder: store.partialPaymentsOrderObj,
              }
          )
        : data;

    $.ajax({
        url: window.patagonia?.clientDataModel?.adyen?.urls?.paymentFromComponentURL,
        type: 'post',
        data: {
            data: JSON.stringify(requestData),
            paymentMethod: document.querySelector('#adyenPaymentMethodName').value,
        },
        success: function success(response) {
            let _response$fullRespons;
            setOrderFormData(response);
            if (
                (_response$fullRespons = response.fullResponse) !== null &&
                _response$fullRespons !== undefined &&
                _response$fullRespons.action
            ) {
                component.handleAction(response.fullResponse.action);
            } else if (response.isApplePay) {
                document.querySelector('#result').value = JSON.stringify(response);
                document.querySelector('#showConfirmationForm').submit();
            } else if (response.paymentError || response.error) {
                component.handleError();
            }
        },
    });
}

function resetPaymentMethod() {
    $('#requiredBrandCode').hide();
    $('#selectedIssuer').val('');
    $('#adyenIssuerName').val('');
    $('#dateOfBirth').val('');
    $('#telephoneNumber').val('');
    $('#gender').val('');
    $('#bankAccountOwnerName').val('');
    $('#bankAccountNumber').val('');
    $('#bankLocationId').val('');
    $('.additionalFields').hide();
    $('.adyen-rendered-method-description').hide();
}

/**
 * Changes the "display" attribute of the selected method from hidden to visible
 */
function displaySelectedMethod(type) {
    let _document$querySelect2;

    // If 'type' input field is present use this as type, otherwise default to function input param
    store.selectedMethod = document.querySelector('#component_'.concat(type, ' .type'))
        ? document.querySelector('#component_'.concat(type, ' .type')).value
        : type;

    resetPaymentMethod();

    const disabledSubmitButtonMethods = [
        'paypal',
        'paywithgoogle',
        'googlepay',
        'amazonpay',
        'applepay',
    ];

    if (window.patagonia?.clientDataModel?.adyen?.features?.klarnaWidgetEnabled) {
        disabledSubmitButtonMethods.push('klarna');
    }

    const disabled = disabledSubmitButtonMethods.findIndex((pm) => type.includes(pm)) > -1;

    const $submitPayment = document.querySelector('button.submit-payment');
    const $submitBilling = document.querySelector('button.continue-to-billing-address');

    // If payment is after billing
    if ($submitPayment) {
        $submitPayment.classList.toggle('d-none', disabled);
    }

    // If billing is after payment
    if ($submitBilling) {
        $submitBilling.classList.toggle('d-none', disabled);
    }

    document.querySelector('#component_'.concat(type)).setAttribute('style', 'display:block');

    // set brand for giftcards if hidden inputfield is present
    store.brand =
        (_document$querySelect2 = document.querySelector('#component_'.concat(type, ' .brand'))) ===
            null || _document$querySelect2 === undefined
            ? undefined
            : _document$querySelect2.value;

    // Show payment method description confidence booster if it exists
    const component = document.querySelector('#component_'.concat(type));
    if (component?.nextSibling?.classList.contains('adyen-rendered-method-description')) {
        component.nextSibling.style.display = 'block';
    }
}

function displayValidationErrors() {
    store.selectedPayment.node.showValidation();
    return false;
}

const selectedMethods = {};

function doCustomValidation() {
    return store.selectedMethod in selectedMethods ? selectedMethods[store.selectedMethod]() : true;
}

function showValidation() {
    return store.selectedPaymentIsValid ? doCustomValidation() : displayValidationErrors();
}

function getInstallmentValues(maxValue) {
    const values = [];

    for (let i = 1; i <= maxValue; i += 1) {
        values.push(i);
    }

    return values;
}

function createShowConfirmationForm(action) {
    if (document.querySelector('#showConfirmationForm')) {
        return;
    }

    const template = document.createElement('template');
    const form =
        '<form method="post" id="showConfirmationForm" name="showConfirmationForm" action="'.concat(
            action,
            '">\n    <input type="hidden" id="additionalDetailsHidden" name="additionalDetailsHidden" value="null"/>\n    <input type="hidden" id="merchantReference" name="merchantReference"/>\n    <input type="hidden" id="orderToken" name="orderToken"/>\n    <input type="hidden" id="result" name="result" value="null"/>\n  </form>'
        );

    template.innerHTML = form;
    document.querySelector('body').appendChild(template.content);
}

module.exports = {
    setOrderFormData,
    assignPaymentMethodValue,
    paymentFromComponent,
    resetPaymentMethod,
    displaySelectedMethod,
    showValidation,
    createShowConfirmationForm,
    getInstallmentValues,
};

function init(addToCartButton) {
    if (addToCartButton.attr('data-age-required') === 'true') {
        addToCartButton.closest('.show-size-active').addClass('keep-buttons');
        $.ajax({
            url: addToCartButton.data('age-url'),
            method: 'GET',
            dataType: 'html',
            success(data) {
                let $ageModal = $('#ageModal');
                if ($ageModal.length === 0) {
                    $('main .page-wrapper').append(data);
                    $ageModal = $('#ageModal');
                    $(document).on('click', '.age-cancel', (e) => {
                        e.preventDefault();
                        $ageModal.modal('hide');
                    });

                    $(document).on('click', '.age-submit', (e) => {
                        e.preventDefault();
                        const pristine = new Pristine($('#age-form')[0]);
                        if (!pristine.validate()) {
                            pristine.showFormErrors();
                        } else {
                            const day = $('#birthday-day').val();
                            const month = $('#birthday-month').val();
                            const year = $('#birthday-year').val();
                            const today = new Date();
                            const bday = new Date(`${year}-${month}-${day}`);
                            const age = new Date(today - bday);
                            if (
                                age.getUTCFullYear() - 1970 >=
                                $(e.currentTarget).data('drinking-age')
                            ) {
                                addToCartButton.attr('data-age-required', false);
                                addToCartButton.trigger('click');
                                $('.age-invalid').removeClass('show');
                                $ageModal.modal('hide');
                            } else {
                                $('.age-invalid').addClass('show');
                            }
                        }
                    });
                }

                if ($('#modalQuickAddSizes').hasClass('show')) {
                    $ageModal.removeClass('modal__content').addClass('modal__bottom');
                } else if ($ageModal.hasClass('modal__bottom')) {
                    // Reset to default
                    $ageModal.removeClass('modal__bottom').addClass('modal__content');
                }

                $ageModal.modal('show');
                $ageModal.on('hidden.bs.modal', () => {
                    addToCartButton.closest('.show-size-active').removeClass('keep-buttons');
                    addToCartButton.closest('.product-tile').trigger('mouseleave');
                });
            },
            error() {
                addToCartButton.closest('.show-size-active').removeClass('keep-buttons');
                addToCartButton.closest('.product-tile').trigger('mouseleave');
            },
        });
        return true;
    }
    return false;
}

export { init };

export default init;

import { initProductTiles } from '../components/productTile';

// @TODO: this needs better handling of various params per page/component
function primeCacheForNextPage(base = document) {
    const button = base.querySelector('.show-more button[data-url]');
    if (button) {
        const showMoreUrl = button.dataset.url;
        if (showMoreUrl) {
            const url = new URL(showMoreUrl);
            // give some images time to load
            setTimeout(() => {
                try {
                    const ajaxUrl = new URL(url);
                    ajaxUrl.searchParams.append('ajax', 1);
                    $.ajax({
                        url: ajaxUrl,
                        data: { selectedUrl: url.toString() },
                        method: 'GET',
                    });
                } catch (e) {
                    console.warn(e);
                }
            }, 2000);
        }
    }
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    const $updates = $results.find(selector);
    const $elem = $(selector);
    $elem.html($updates.html());
    initProductTiles($elem[0]);
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results, selector) {
    // keep sections expanded unless this is a reset
    $(selector)
        .find(
            '.shopping-refinements__refinement:not(.shopping-refinements__refinement--sort) .collapse.show'
        )
        .each(function () {
            const attr = $(this)
                .closest('.shopping-refinements__refinement')
                .attr('data-attribute');
            $results.find(`[data-attribute=${attr}] .collapse`).addClass('show');
        });

    updateDom($results, selector);
}

/**
 * Replace product grid with products, in edge case where refining results in no products, reload page to display correct "no results" page
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleProducts($results, selector) {
    if ($results.find(selector).length > 0) {
        updateDom($results, selector);
    }
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response, opts) {
    if (!response) {
        return;
    }
    const $results = $(`<div>${response}</div>`);

    const specialHandlers = {
        '[data-shopping-tool] .shopping-refinements__content': handleRefinements,
        '[data-shopping-tool-sidebar] .shopping-refinements__content': handleRefinements,
        '.product-grid': handleProducts,
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.show-more',
        '.shopping-sort-header',
        '.filter-refine-pills',
        '.filter-refine__mega-refinements .swiper-wrapper',
    ].forEach((selector) => {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach((selector) => {
        specialHandlers[selector]($results, selector);
    });

    if (opts.type === 'cat-tabs') {
        if ($results.find('.search-results[data-hero-title]')) {
            const title = $results.find('.search-results[data-hero-title]').data('hero-title');
            if (title && title.length > 0) {
                document.querySelector('h1').innerText = title;
            }
        }
    }

    // reload the shopping tool
    const reload = document.createEvent('Event');
    reload.initEvent('ReloadShoppingTool-Refinements', true, true);
    document.dispatchEvent(reload);
}

export { primeCacheForNextPage, parseResults };

export default {};

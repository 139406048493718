/* eslint-disable camelcase, no-undef */
__webpack_public_path__ = (window.Urls || []).staticPath;
if (
    window.location.host.includes('demandware') ||
    window.location.host.includes('commercecloud.salesforce.com')
) {
    __webpack_public_path__ = (window.Urls || [])?.staticPath?.replace(/\/v\d+\//, '/');
}
window.__webpack_public_path__ = __webpack_public_path__;
/* eslint-enable camelcase, no-undef */

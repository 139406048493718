export default function () {
    const $password = $('input[type="password"].password-assist');

    if ($password.length > 0) {
        const $passwordAssist = $password.data('password-assist');
        const $resourceHeader = $passwordAssist.header;
        const $resourceCheck1 = $passwordAssist.check1;
        const $resourceCheck2 = $passwordAssist.check2;
        const $resourceCheck3 = $passwordAssist.check3;
        const $resourceCheck4 = $passwordAssist.check4;
        const $resourceStrength = $passwordAssist.strength;
        const $resourceInstructions = $passwordAssist.instructions;

        const $check =
            '<figure class="icon icon--check"><svg class="colored"><use href="#icon--check"></use></svg></figure> ';

        if ($password.length > 0) {
            $password.each((i, elm) => {
                const template = [
                    `<div class="password-assist" id="password-assist-${i}">`,
                    `    <p class="resourceHeader">${$resourceHeader}</p>`,
                    `    <div class="check check-1">${$check}${$resourceCheck1}</div>`,
                    `    <div class="check check-2">${$check}${$resourceCheck2}</div>`,
                    `    <div class="check check-3">${$check}${$resourceCheck3}</div>`,
                    `    <div class="check check-4">${$check}${$resourceCheck4}</div>`,
                    '    <div class="strength">',
                    `        ${$resourceStrength}`,
                    '         <div class="bar-wrapper"><div class="bar"></div></div>',
                    '    </div>',
                    $resourceInstructions !== ''
                        ? `    <div class="instructions">${$resourceInstructions}</div>`
                        : '',
                    '</div>',
                ];

                const $elm = $(elm);
                const $tool = $(`#password-assist-${i}`);

                // Set default wrapper
                let $passwordWrapper = $elm.closest('.custom-input');

                // Add fallback support for forms that do not use custom input wrapper
                if ($passwordWrapper.length === 0) {
                    $passwordWrapper = $elm.closest('.form-group');
                }

                // Check if tool was already injected before adding it
                if ($tool.length === 0) {
                    $passwordWrapper.append(template.join('\n'));
                }

                // Remove pre-existing name-spaced password assist binding
                $elm.off('blur.password-assist');
                $elm.off('focus.password-assist');
                $elm.off('keyup.password-assist');
                $(window).off('resize.password-assist');

                $elm.on('blur.password-assist', () => {
                    $tool.hide();
                });

                $elm.on('focus.password-assist', () => {
                    // Empty password will show native browser password autocomplete, so wait until user starts typing
                    if ($elm.val() !== '') {
                        $tool.show();
                    }
                });

                $elm.on('keyup.password-assist touchend.password-assist', () => {
                    const pass = $elm.val();
                    let score = 0;
                    const scale = ['empty', 'weak', 'moderate-1', 'moderate-2', 'strong'];

                    // Reset Tool
                    $tool.removeClass(scale.join(' '));
                    $('.check', $tool).removeClass('passed');

                    if (pass !== '') {
                        // Show tool if hidden
                        if ($tool.is(':hidden')) {
                            $tool.show();
                        }

                        // Upper & lowercase letters
                        if (/[A-Z]/.test(pass) && /[a-z]/.test(pass)) {
                            $('.check-1', $tool).addClass('passed');
                            score += 1;
                        }

                        // At least one number
                        if (/[0-9]/.test(pass)) {
                            $('.check-2', $tool).addClass('passed');
                            score += 1;
                        }

                        // Check for Special Characters
                        if (/[^a-zA-Z0-9 ]/.test(pass)) {
                            $('.check-3', $tool).addClass('passed');
                            score += 1;
                        }

                        // Check Password Length
                        if (pass.length >= 8) {
                            $('.check-4', $tool).addClass('passed');
                            score += 1;
                        }
                    } else {
                        $tool.hide();
                    }

                    $tool.addClass(scale[score]);
                });
            });
        }
    }
}

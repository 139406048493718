function loadImage(src) {
    return new Promise((resolve) => {
        const node = new Image();
        node.onload = () => {
            resolve({
                node,
                naturalWidth: node.naturalWidth,
                naturalHeight: node.naturalHeight,
                aspectRatio: node.naturalHeight / node.naturalWidth,
                scale: 1,
                width: 0,
                height: 0,
                left: 0,
                top: 0,
            });
        };

        if (/^([\w]+:)?\/\//.test(src) && src.indexOf(window.location.host) === -1) {
            node.crossOrigin = 'anonymous'; // or "use-credentials"
        }
        node.src = src;
    });
}

function loadVideo(src) {
    return new Promise((resolve) => {
        const node = document.createElement('video');
        node.oncanplay = () => {
            node.play()
                .then(() => {
                    resolve({
                        node,
                        naturalWidth: node.videoWidth,
                        naturalHeight: node.videoHeight,
                        aspectRatio: node.videoHeight / node.videoWidth,
                        scale: 1,
                        width: 0,
                        height: 0,
                        left: 0,
                        top: 0,
                    });
                })
                .catch((e) => {
                    console.warn(e); // eslint-disable-line no-console
                });
        };

        node.src = src;
        node.muted = true;
        node.loop = true;
        node.playsinline = true;

        node.load();
    });
}

function coverImage(bounds, aspectRatio) {
    const [width, height] = bounds;
    let w = width;
    let h = w * aspectRatio;
    if (h < height) {
        h = height;
        w = h / aspectRatio;
    }

    return {
        width: w,
        height: h,
        left: (width - w) / 2,
        top: (height - h) / 2,
    };
}

// don't ask
function quadraticBezierLength(x1, y1, x2, y2, x3, y3) {
    const v1x = x2 * 2;
    const v1y = y2 * 2;

    const d = x1 - v1x + x3;
    const d1 = y1 - v1y + y3;

    const e = v1x - 2 * x1;
    const e1 = v1y - 2 * y1;

    let a = 4 * (d * d + d1 * d1);
    const b = 4 * (d * e + d1 * e1);
    let c = e * e + e1 * e1;

    let c1 = a;
    c1 += b;
    c1 += c;
    c1 = 2 * Math.sqrt(c1);

    const u = Math.sqrt(a);

    const a1 = 2 * a * u;
    const u1 = b / u;

    a = 4 * c * a - b * b;
    c = 2 * Math.sqrt(c);

    return (a1 * c1 + u * b * (c1 - c) + a * Math.log((2 * u + u1 + c1) / (u1 + c))) / (4 * a1);
}

// see: https://abdulapopoola.com/2016/12/19/why-javascript-has-two-zeros-0-and-0/
function isNegativeZero(val) {
    let value = val;
    value = +value; // cast to number
    if (value) {
        return false;
    }
    if (value !== 0) {
        return false;
    }
    const infValue = 1 / value;
    return infValue < 0;
}

function sanitizeForNegativeZero(val) {
    if (isNegativeZero(val)) {
        return 0;
    }
    return val;
}

const dataPrefix = 'data-blob';

export {
    loadImage,
    loadVideo,
    coverImage,
    quadraticBezierLength,
    dataPrefix,
    sanitizeForNegativeZero,
};

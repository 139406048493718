export const processInclude = function (includedFunctions) {
    let include = includedFunctions;
    if (
        'default' in includedFunctions &&
        '__esModule' in includedFunctions &&
        includedFunctions.__esModule === true
    ) {
        include = includedFunctions.default;
    }
    if (typeof include === 'function') {
        include();
    } else if (typeof include === 'object') {
        Object.keys(include).forEach((key) => {
            if (typeof include[key] === 'function') {
                include[key]();
            }
        });
    }
};

/*
    This is a utility function that is used to get the breakpoint of the current screen.
    @return {String} the name of the breakpoint
*/
export const getBreakPoint = function () {
    const screenWidth = window.innerWidth || document.body.clientWidth;

    const bpNames = [
        'tiny',
        'small',
        'medium',
        'large',
        'xlarge',
        'xxlarge',
        'xxxlarge',
        'xxxxlarge',
    ];
    const bpSizes = [480, 576, 769, 992, 1200, 1400, 1600, 1800];

    for (let i = 0; i < bpSizes.length; i += 1) {
        if (screenWidth < bpSizes[i]) {
            return bpNames[i];
        }
    }

    return bpNames[bpNames.length - 1];
};

export const getCookie = function (name) {
    const cookie = {};
    document.cookie.split(';').forEach((el) => {
        const [k, v] = el.split('=');
        cookie[k.trim()] = v;
    });
    return cookie[name];
};

export const createCookie = function (name, value, days, path, domain) {
    let cookie = `${name}=${value}`;

    if (path != null) {
        cookie = `${cookie}; path=${path}`;
    }

    if (days != null) {
        if (days === 0) {
            cookie = `${cookie}; expires=0`;
        } else {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            cookie = `${cookie}; expires=${date.toGMTString()}`;
        }
    }

    if (domain != null) cookie = `cookie; domain=${domain}`;

    document.cookie = cookie;
};

export const removeDuplicates = function (arr) {
    const unique = {};
    arr.forEach((i) => {
        if (!unique[i]) {
            unique[i] = true;
        }
    });
    return Object.keys(unique);
};

/**
 * @description Simple function to sum up items in an object, might have a use case down the line
 * @param {Object} obj - takes in an object with numbers for summing up
 * @returns the sum of the items in the object.
 */
export const sumObject = function (obj) {
    const sumOfObject = Object.values(obj).reduce((a, b) => a + b);
    return sumOfObject;
};

/**
 * @description - gets mode from set of numbers
 * @param arr - the array being parsed
 * */

export const getMode = (arr) => {
    const obj = {};

    // loop through array and add a key to obj to correspond with the item in the array, if theres already a corresponding key, increment by one.
    // for loop is faster than forEach so falling back to that
    for (let i = 0; i < arr.length; i += 1) {
        const number = arr[i];
        if (!obj[number]) {
            obj[number] = 1;
        } else {
            obj[number] += 1;
        }
    }

    // looks for key with highest value
    // key is also coerced to a number
    let highestValue = 0;
    let highestValueKey = -1;

    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (value >= highestValue && Number(key) > highestValueKey) {
            highestValue = value;
            highestValueKey = Number(key);
        }
    });

    return highestValueKey;
};

export default processInclude;
